<template>
    <div class="Awarp">
        <dashCard class="lvscBox14" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">断路器基础信息</template>
            <template slot="aside">
                <a-select v-model="searchObj['ITEM']" style="width: 220px" @change="handleChange" :getPopupContainer="triggerNode => triggerNode.parentNode">
                    <a-select-option v-for="(item,key) in itemList" :key="key" :value="item.code">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </template>
            <div class="card-content1">
                    <div class="product-pre">
                        <div>
                            <img :src="detailInfo.productSrc"/>
                        </div>
                    </div>
                    <div class="product-info">
                        <div class="product-info-item">
                            <div class="product-info-item-top">
                                <span>运行电流</span>
                                <span>300A</span>
                            </div>
                            <div class="product-info-item-progress">
                                <span style="width:30%"></span>
                            </div>
                            <div class="product-info-item-bottom">
                                <span>0</span>
                                <span>1000A</span>
                            </div>
                        </div>
                        <div class="product-info-item">
                            <div class="product-info-item-top">
                                <span>运行电压</span>
                                <span>380V</span>
                            </div>
                            <div class="product-info-item-progress">
                                <span style="width:95%"></span>
                            </div>
                            <div class="product-info-item-bottom">
                                <span>0</span>
                                <span>400V</span>
                            </div>
                        </div>

                        <div class="product-info-item">
                            <div class="product-info-item-top">
                                <span>运行功率</span>
                                <span>200kW</span>
                            </div>
                            <div class="product-info-item-progress">
                                <span style="width:50%"></span>
                            </div>
                            <div class="product-info-item-bottom">
                                <span>0</span>
                                <span>400kW</span>
                            </div>
                        </div>

                        <div class="product-info-item">
                            <div class="product-info-item-top">
                                <span>运行温度</span>
                                <span>80℃</span>
                            </div>
                            <div class="product-info-item-progress">
                                <span style="width:88.9%"></span>
                            </div>
                            <div class="product-info-item-bottom">
                                <span>0</span>
                                <span>90℃</span>
                            </div>
                        </div>

                    </div>
            </div>
            <a-descriptions bordered>
                <a-descriptions-item v-for="(item,idx) in columns" :key="idx" :label="item.title" :span="3">
                    <span v-if="!!item.value1">{{ detailInfo[item.value1] }}</span>
                    <span v-if="!!item.value2"> {{ detailInfo[item.value2] }}</span>
                </a-descriptions-item>
            </a-descriptions>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <lvscBox14  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    import Img001 from "@/assets/imgs/add/product1.png"
    export default {
        name: 'lvscBox14',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                itemList: [
                {
                    code: '1AT1_抽屉柜',
                    name:'1AT1_抽屉柜'
                },
                {
                    code: '1AT2_抽屉柜',
                    name:'1AT2_抽屉柜'
                },
                {
                    code: '1AT3_抽屉柜',
                    name:'1AT3_抽屉柜'
                }
            ],

            columns: [
                { title: '型号', value1: 'value1'},
                { title: '上次维护时间', value1: 'value2',value2: 'value3'},
                { title: '额定电压', value1: 'value4',value2: 'value5'},
                { title: '额定电流', value1: 'value6',value2: 'value7',},
                { title: '额定频率', value1: 'value8',value2: 'value9'},
            ],
            searchObj: {
                ITEM: '1AT1_抽屉柜',//选中的项目
            },
            detailInfo: {},
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width  
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

        // 切换项目
        handleChange(value) {
            this.getData()
        },
        onChange() {
            this.getData()
        },
        // 获取数据
        getData() {
            this.isLoading = true
            this.detailInfo = {
                value1:"ABB_3P 63A-400A",
                value2:"128",
                value3:"h",
                value4:"400",
                value5:"V",
                value6:"1000",
                value7:"A",
                value8:"2780",
                value9:"kWh",


                value10:"380V",
                value11:"200kW",
                value12:"80℃",
                productSrc: Img001
            }
            this.isLoading = false
        },

            
        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>
    
<style lang="less" scoped>
.lvscBox14 {
    .card-content1 {
        display: flex;
        .product-pre {
            width: 362px;
            height: 336px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .product-info {
            flex: 1;
            .product-info-item {
                margin-bottom: 24px;
                .product-info-item-top {
                display: flex;
                justify-content: space-between;

                font-size: 16px;
                font-weight: 500;
                color: #1f1f1f;
                line-height: 20px;
                } 
                .product-info-item-progress {
                width: 100%;
                height: 8px;
                background: #dbdbdb;
                margin-top: 14px;
                margin-bottom: 9px;
                span {
                    display: block;
                    height: inherit;
                    width: 0%;
                    background: #51A34B;
                }
                }
                &:nth-child(1) {
                .product-info-item-progress {
                    span {
                        background: #51A34B;
                    }
                }
                }
                &:nth-child(2) {
                .product-info-item-progress {
                    span {
                        background: #F1404F;
                    }
                }
                }
                &:nth-child(3) {
                .product-info-item-progress {
                    span {
                        background: #FF7300;
                    }
                }
                }
                &:nth-child(4) {
                .product-info-item-progress {
                    span {
                        background: #FF7300;
                    }
                }
                }
                .product-info-item-bottom {
                display: flex;
                justify-content: space-between;

                font-size: 14px;
                font-weight: 400;
                color: #696969;
                line-height: 16px;
                }
            }
            
        }

    }
    /deep/.ant-descriptions-bordered {
        .ant-descriptions-item-label {
            background: transparent;
            width: 362px;
        }
        .ant-descriptions-item-content {
            background: #F5F5F5;
            display: flex;
            justify-content: space-between;
        }
    }

}
</style>